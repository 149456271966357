export function isArabic(input) {
  let arabicAlphabet = /^[\s\u0600-\u06FF]+$/;
  for (let char of input) {
    if (arabicAlphabet.test(char) === false) {
      return false;
    }
  }

  return true;
}
export function validTelephone(input) {
  if (input == "") return true;
  const starter = [
    "011",
    "014",
    "015",
    "016",
    "021",
    "022",
    "023",
    "031",
    "033",
    "041",
    "043",
    "051",
    "052",
    "053",
  ];
  let is_telephone = false;
  if (input.length != 10) return is_telephone;
  input = input.substring(0, 3);
  for (let i = 0; i < starter.length; i++)
    if (input == starter[i]) is_telephone = true;
  return is_telephone;
}
export function isNumber(input) {
  const eNumerals = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  let isNum = false;
  for (let char of input)
    for (let num in eNumerals) {
      if (char === num) isNum = true;
    }
  if (isNum) return true;
  const aNumerals = /[\u0660-\u0669]/;
  isNum = aNumerals.test(input);
  return isNum;
}
export function isArabicText(input) {
  if (input === "") return true;
  return isArabic(input) && !isNumber(input);
}
export function validEmail(input) {
  if (input === "") return true;
  const pattern = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$");
  return pattern.test(input);
}
export function validPhoneNumber(number) {
  if (number === "") return true;
  let isValid = false;
  if (number.length === 10) {
    const pattern = /^09\d{8}$/;
    isValid = pattern.test(number);
  }
  return isValid && isNumber(number);
}
export function validCode(code) {
  if (code === "") return true;
  if (code.length !== 4) return false;
  else return true;
}
let sugeryId;
export function setSugeryId(value) {
  sugeryId = value;
}
export function getSugeryId() {
  return sugeryId;
}
let appoitmentId;
export function setAppoitmentId(value) {
  appoitmentId = value;
}
export function getAppoitmentId() {
  return appoitmentId;
}
