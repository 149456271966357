import React from "react";
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {IconButton,Typography,List,ListItem,ListItemButton,ListItemText} from "@mui/material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import KeyIcon from '@mui/icons-material/Key';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import  axios  from "axios";
import { useNavigate } from "react-router-dom";
export default function SettingsModal({setOpen,setOpenPassChange,setOpenNumChange}) {
  const navigate=useNavigate();
  const deleteAccount=()=>{
    const token=localStorage.getItem('token');
    axios.delete('https://ahmadproject.serv00.net/api/user/776',{
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response=>{
      
  })
  .catch(error => {
        
    if (error.response && error.response.status >= 500 && error.response.status < 600) {
      // Display an alert for server error
      window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
    } else {
      // Display an alert with the error message from the server
      window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
    }
  })
  }
  
  return (
    <div className="modal-bg center-div">
      <div className="modal-container">
        <div className="modal-header">
          <IconButton onClick={()=>{setOpen(false)}} style={{padding:"5px", float:"right" , paddingBottom:"0px"}}><ArrowBackIcon className="modal-icon"/></IconButton>
          <Typography variant="h4" component="div" sx={{ flexGrow: 1 }} 
          className='modal-name'>الإعدادت</Typography>
        </div>
        <div className="modal-body">
        <List>
        <ListItem className="modal-list-items" disablePadding>
            <ListItemButton onClick={()=>{setOpenPassChange(true)}}>
                <ListItemText primary={"تغيير كلمة السر"}className='modal-primary-text'/>
                <ListItemIcon><KeyIcon className="modalopt-icon"/></ListItemIcon>
            </ListItemButton>
        </ListItem>
        <ListItem className="modal-list-items" disablePadding>
        <ListItemButton onClick={()=>{setOpenNumChange(true)}}>
                <ListItemText primary={"تغيير رقم الهاتف"} className='modal-primary-text'/>
                <ListItemIcon><LocalPhoneIcon className="modalopt-icon"/></ListItemIcon>
            </ListItemButton>
        </ListItem>
        <ListItem className="modal-list-items" disablePadding>
        <ListItemButton onClick={()=>{if (window.confirm('هل انت متأكد من انك تريد حذف الحساب؟')) {
              deleteAccount();
              localStorage.setItem('token',null)
              navigate("/home")
              }}}>
        <ListItemText primary={"حذف الحساب"} className='modal-primary-text'/>
                <ListItemIcon><PersonRemoveIcon className="modalopt-icon"/></ListItemIcon>
            </ListItemButton>
        </ListItem>
      </List>
        </div>
      </div>
    </div>
  );
}

                