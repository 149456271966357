import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {IconButton,Typography} from "@mui/material";
import axios from "axios";
export default function ChangePassModal({setOpen}) {
    const [password, setPassword] = React.useState('');
  const [new_password, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    if(new_password===confirmPassword&&password!='')
      sendData();
    else return
  };
  const sendData=()=>{
    const url='https://ahmadproject.serv00.net/api/user/restPasswordWithOldPassword';
    const info={
      password,
      new_password
    }
    const token =localStorage.getItem('token');
    axios.post(url,info,{
      headers:{
          'Authorization': `Bearer ${token}`
      }
    })
    .then(response=>{
      window.alert("تم التعديل بنجاح")
     setOpen(false)
  })
  .catch(error => {
   console.log(error)
   if(error.response&&error.response.status==401)
    window.alert('خطأ في كلمة السر')
    else if (error.response && error.response.status >= 500 && error.response.status < 600) {
      // Display an alert for server error
      window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
    } else {
      // Display an alert with the error message from the server
      window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
    }
  })
  }
    
    return(
    <div className="modal-bg center-div">
      <div className="modal-container" >
        <div className="modal-header">
          <IconButton onClick={()=>{setOpen(false)}} style={{padding:"5px", float:"right" , paddingBottom:"0px"}}><ArrowBackIcon className="modal-icon"/></IconButton>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} 
          className='modal-name'>تغيير كلمة السر</Typography>
        </div>
        <div className="modal-body">
            <form>
                <div className="input-group">
                <input required type="password" autoComplete="off" className="input"
                onChange={(e)=>{
                  setPassword(e.target.value);
                }} />
                <label className="user-label">كلمة السر القديمة</label>
                </div>
                {password.length<8&&password!==''?<label className="invalid-input-mes">يجب ان تكون كلمة السر ٨ محارف على الاقل</label>:<></>}
                <div className="input-group">
                <input required type="password" autoComplete="off" className="input"
                onChange={(e)=>{
                  setNewPassword(e.target.value);
                }}/>
                <label className="user-label">كلمة السر الجديدة</label>
                </div>
                {new_password.length<8&&new_password!==''?<label className="invalid-input-mes">يجب ان تكون كلمة السر ٨ محارف على الاقل</label>:<></>}
                <div className="input-group">
                <input required type="password" autoComplete="off" className="input"
                onChange={(e)=>{
                  setConfirmPassword(e.target.value);
                }}/>
                <label className="user-label">تأكيد كلمة السر</label>
                </div>
                <div>
                {confirmPassword.length<8&&confirmPassword!==''?<label className="invalid-input-mes">يجب ان تكون كلمة السر ٨ محارف على الاقل</label>:<></>}
                <div className="center-div">
                </div>
                <button type="submit" className="modal-submitbtn"
                onClick={handleSubmit}>حفظ</button>
                </div>
            </form>
        </div>
        </div>
        </div>
    )
}
