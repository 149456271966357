import React from "react";
import {isArabicText,validEmail, validPhoneNumber} from "../validations.js";
import { Link } from "react-router-dom";
import CheckCodeModal from "../modals/CheckCodeModal.jsx";
import axios from 'axios';
import "../App.css"
export default function SignUp(){
    const [fname,setFname]=React.useState('');
    const [mname,setMname]=React.useState('');
    const [lname,setLname]=React.useState('');
    const [email,setEmail]=React.useState('');
    const [phone_num,setPhoneNum]=React.useState('');
    const [password,setPassword]=React.useState('');
    const [confirm_password,setConfirmPassword]=React.useState('');
    const [allowPersonalData,setAllowPersonalData]=React.useState(false);
    const [openCodeCheck,setOpenCodeCheck]=React.useState(false)
   
    const handleSubmit=(e)=>{
        e.preventDefault();
               
        if(isArabicText(fname)&&isArabicText(mname)&&isArabicText(lname)&&validEmail(email)&&validPhoneNumber(phone_num)&&password===confirm_password&&allowPersonalData==true)
            sendData();
        else 
        window.alert(' الرجاء ملئ جميع الحقول بشكل صحيح والموافقة على شروط الخصوصية')
    }
   
    const sendData = () => {
        const url="https://ahmadproject.serv00.net/api/user/register";
        const info = {
            email,
            phone_num,
            password
          };
        axios.post(url,info)
        .then(response=>{
            setOpenCodeCheck(true)
        })
        .catch(error => {
            if(error.response&&error.response.status===422){
                window.alert('ان رقم الهاتف او البريد الالكتروني خاطئ او مسجل بالفعل الرجاء اختيار رقم هاتف او بريد الكتروني اخر')
            }
            else if (error.response && error.response.status >= 500 && error.response.status < 600) {
              // Display an alert for server error
              window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
            } else {
              // Display an alert with the error message from the server
              window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
            }
          });
        }
    
    
    return(
        <>
        <div className="page-background center-div">
            <div className="form-contener">
                <form>
                <div className='center-div page-title'>
                    <h1 style={{margin:"10px 0"}}>إنشاء حساب</h1>
                </div>
                <div className="center-div form-body">
                <input type="text" placeholder="الاسم الاول" className="input-box" required={true}
                style={!isArabicText(fname)?{borderColor:"red"}:{borderColor:"lightgray"}}
                onChange={(e)=>{
                    setFname(e.target.value);
                }}/>
                </div>
                {/* error message */}
                {!isArabicText(fname)?<label className="invalid-input-mes">الرجاء ادخال الاسم بالعربية فقط</label>:<></>}
                <div className="center-div form-body">
                <input type="text" placeholder="اسم الاب" className="input-box" required
                style={!isArabicText(mname)?{borderColor:"red"}:{borderColor:"lightgray"}}
                onChange={(e)=>{
                    setMname(e.target.value);
                }}/>
                </div>
                {/* error message */}
                {!isArabicText(mname)?<label className="invalid-input-mes">الرجاء ادخال اسم الاب بالعربية فقط</label>:<></>}
                <div className="center-div form-body">
                <input type="text" placeholder="الكنية / اللقب" className="input-box" required
                style={!isArabicText(lname)?{borderColor:"red"}:{borderColor:"lightgray"}}
                onChange={(e)=>{
                    setLname(e.target.value);
                }}/>
                </div>
                {/* error message */}
                {!isArabicText(lname)?<label className="invalid-input-mes">الرجاء ادخال اللقب / الكنية بالعربية فقط</label>:<></>}
                <div className="center-div form-body">
                <input type="email" placeholder="البريد الالكتروني" className="input-box" required
                style={!validEmail(email)?{borderColor:"red"}:{borderColor:"lightgray"}}
                onChange={(e)=>{
                    setEmail(e.target.value);
                }}
                />
                </div>
                {/* error message */}
                {!validEmail(email)?<label className="invalid-input-mes">الرجاء ادخال البريد الالكتروني بطريقة صحيحة</label>:<></>}
                
                <div className="center-div form-body">
                <input type="tel" placeholder="رقم الهاتف" className="input-box" required
                pattern = "^09\d{8}$"
                style={!validPhoneNumber(phone_num)?{borderColor:"red"}:{borderColor:"lightgray"}}
                onChange={(e)=>{
                    setPhoneNum(e.target.value);
                }}
                />
                </div>
                {/* error message */}
                {!validPhoneNumber(phone_num)?<label className="invalid-input-mes">الرجاء ادخال رقم الهاتف بشكل صحيح</label>:<></>}
                <div className="center-div form-body">
                <input type="password" placeholder="كلمة السر" className="input-box" required
                minLength="8"
                style={password.length<8&&password!==''?{borderColor:"red"}:{borderColor:"lightgray"}}
                onChange={(e)=>{
                    setPassword(e.target.value);
                }}
                />
                </div>
                {/* error message */}
                {password.length<8&&password!==''?<label className="invalid-input-mes">يجب ان تكون كلمة السر ٨ محارف على الاقل</label>:<></>}
                <div className="center-div form-body">
                <input type="password" placeholder="تأكيد كلمة السر" className="input-box" required
                minLength="8"
                style={password!==confirm_password&&confirm_password!==''?{borderColor:"red"}:{borderColor:"lightgray"}}
                onChange={(e)=>{
                    setConfirmPassword(e.target.value);
                }}
                />
                </div>
                {/* error message */}
                {password!==confirm_password&&confirm_password!==''?<label className="invalid-input-mes">تأكيد كلمة المرور لا يتطابق مع كلمة المرور الاصلية</label>:<></>}
                

                <div id="info-checkbox" className="form-body">
                    <input type="checkbox" className="form-checkbox"
                    onClick={()=>{setAllowPersonalData(!allowPersonalData)}}/>
                    <label className="info-label info-text">انا اوافق على </label>
                    <a href="\" className="info-label links">شروط الاستخدام و الخصوصية</a>
                </div>

                <div className="form-body">
                    <button type="submit" onClick={handleSubmit} className="form-submitbtn">أنشاء حساب</button>
                </div>
                <div className="form-links">
                    <div style={{float:"right", marginRight:"15px"}}>
                <Link to="../Sign-in-using-email" className="info-label links">تسجيل الدخول</Link>
                </div>
                </div>
                <div className="form-links">
                <div style={{float:"left", marginLeft:"15px" ,textAlign:"left"}}>
                </div>
                </div>
                </form>
            </div>
        </div>
        {openCodeCheck?<CheckCodeModal setOpen={setOpenCodeCheck}/>:<></>}
    </>
    )
}