import React from "react";
import Home from "./pages/Home";
import SurgeryApplicant from "./pages/SugeryApplicant";
import SigninNum from "./pages/NumSignIn";
import SigninEmail from "./pages/EmailSignIn";
import SignUp from "./pages/Register";
// import Test from "./components/test";
import GuestHome from "./pages/GuestHome";
import FullRequest from "./pages/FullRequest";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Outlet,
} from "react-router-dom";
export default function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route
          index
          element={
            <LogedIn>
              <Home />
            </LogedIn>
          }
        />
        <Route path="Home" element={<GuestHome />} />
        <Route path="Register" element={<SignUp />} />
        <Route path="Sign-in-using-email" element={<SigninEmail />} />
        <Route path="Sign-in-using-phone-number" element={<SigninNum />} />
        <Route
          path="apply-to-surgery"
          element={
            <LogedIn>
              <SurgeryApplicant />
            </LogedIn>
          }
        />
        <Route
          path="show-applicant"
          element={
            <LogedIn>
              <FullRequest />
            </LogedIn>
          }
        />
        <Route
          path="*"
          element={
            <>
              <div className="center-div">
                <h1>404</h1>
              </div>
              <br />
              <div className="center-div">
                <h2>page not found</h2>
              </div>
            </>
          }
        />
      </Route>
    )
  );
  const Root = () => {
    return (
      <>
        <div>
          <Outlet />
        </div>
      </>
    );
  };
  return <RouterProvider router={router} />;
  function LogedIn({ children }) {
    const token = localStorage.getItem("token");
    if (token) return <>{children}</>;
    else
      return (
        <>
          <GuestHome />
        </>
      );
  }
  // function NotLogedIn({ children }) {
  //   const token = localStorage.getItem("token");
  //   if (!token) return <>{children}</>;
  //   else
  //     return (
  //       <>
  //         <div>not allowed</div>
  //       </>
  //     );
  // }
}
