import React from "react";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from "react-router-dom";
import "../App.css"



export default function GuestHome(){
    const navigate=useNavigate();
    return<>
    <div id="home-body">
    
       
        <div className="center-div" id="home-image">
        <img src="../../assests/surgery.jpg" id='guest-homebg'/>
        </div>
        <div className="center-div">
            <div className="homebtn-row">
            <div className="guest-homebtn-box">
                <ListItemButton style={{display:"block",margin:"0",padding:"0",textAlign:"right"}}
                onClick={()=>{
                    if(window.confirm("ان التسجيل على عملية يحتاج الى تسجيل دخول \n هل تريد تسجيل الدخول؟"))
                        navigate("../Sign-in-using-email")
                    
                }}
                >
                    <ListItemIcon><AirlineSeatFlatIcon className="homebtn-icons"/></ListItemIcon>
                    <h2 className="homebtn-title">التقدم إلى عملية</h2>
                    <span className="homebtn-disc">اضغط هنا للتقدم لتعبئة الاستمارة</span>
                </ListItemButton>
            </div>
            <div className="guest-homebtn-box">
                <ListItemButton style={{display:"block",margin:"0",padding:"0",textAlign:"right"}}
                onClick={()=>{
                    navigate('../Register');
                }}>
                    <ListItemIcon><PersonOutlinedIcon className="homebtn-icons"/></ListItemIcon>
                    <h2 className="homebtn-title">أنشاء حساب</h2>
                    <span className="homebtn-disc">يمكنك الضغط هنا للقيام بأنشاء حساب جديد</span>
                </ListItemButton>
            </div>
            </div>
            <div className="homebtn-row">
            <div className="guest-homebtn-box">
                <ListItemButton style={{display:"block",margin:"0",padding:"0",textAlign:"right"}}
                onClick={()=>{
                    navigate('../Sign-in-using-email');
                }}>
                    <ListItemIcon><LoginIcon className="homebtn-icons"/></ListItemIcon>
                    <h2 className="homebtn-title small-title">تسجيل دخول عبر البريد الالكتروني</h2>
                    <span className="homebtn-disc small-dics">يمكنك تسجيل الدخول عن طريق البريد الالكتروني</span>
                </ListItemButton>
            </div>
            <div className="guest-homebtn-box">
                <ListItemButton style={{display:"block",margin:"0",padding:"0",textAlign:"right"}}
                onClick={()=>{
                    navigate('../Sign-in-using-email');
                }}>
                    <ListItemIcon><LoginIcon className="homebtn-icons"/></ListItemIcon>
                    <h2 className="homebtn-title small-title">تسجيل دخول عبر رقم الهاتف </h2>
                    <span className="homebtn-disc small-dics">يمكنك تسجيل الدخول عن طريق رقم الهاتف </span>
                </ListItemButton>
            </div>
            </div>
        </div>
    </div>
   </>
}