import React from "react";

export default function Request({request}){

    return(
        <div id="request-box">
            <div id="request-header">
                <h3>{request.surgery_name}</h3>
            </div>
            <div id="request-body">
                <span>{request.full_name}</span><br/>
                <span>{request.medical_dept_name}</span><br/>
                <span>{request.applying_date}</span>
            </div>
        </div>
    )
}