import React from "react";
import  axios  from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {IconButton,Typography} from "@mui/material";
import { validPhoneNumber } from "../validations";
export default function ChangeNumberModal({setOpen}) {
    const [oldPhoneNum, setOldNumber] = React.useState('');
  const [phone_num, setNewNumber] = React.useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    // getData();
    if(oldPhoneNum===''||phone_num==='')
    return
  
    sendData();
  };
  const sendData=()=>{
    const url='https://ahmadproject.serv00.net/api/user/0'
    const info={
      phone_num
    }
    const token =localStorage.getItem('token');
    axios.put(url,info,{
      headers:{
        'Authorization': `Bearer ${token}`
    }
    })
    .then(response=>{
      window.alert("تم التعديل بنجاح")
      setOpen(false)
  })
  .catch(error => {
    if(error.response&&error.response.status===401)
        window.alert('خطأ في رقم الهاتف')
    else
    if (error.response && error.response.status >= 500 && error.response.status < 600) {
      // Display an alert for server error
      window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
    } else {
      // Display an alert with the error message from the server
      window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
    }
  })
  }
    
    return(
    <div className="modal-bg center-div">
      <div className="modal-container">
        <div className="modal-header">
          <IconButton onClick={()=>{setOpen(false)}} style={{padding:"5px", float:"right" , paddingBottom:"0px"}}><ArrowBackIcon className="modal-icon"/></IconButton>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} 
          className='modal-name'>تغيير رقم الهاتف</Typography>
        </div>
        <div className="modal-body">
            <form>
                <div className="input-group">
                <input required type="tel" autoComplete="off" className="input"
                onChange={(e)=>{
                  setOldNumber(e.target.value);
                }}/>
                <label className="user-label">رقم الهاتف القديم</label>
                </div>
                {oldPhoneNum.length!=10&&oldPhoneNum!=''||!validPhoneNumber(oldPhoneNum)?<label className="invalid-input-mes">تاكد من صحة رقم الهاتف</label>:<></>}
                <div className="input-group">
                <input required type="tel" autoComplete="off" className="input"
                onChange={(e)=>{
                  setNewNumber(e.target.value);
                }}/>
                <label className="user-label">رقم الهاتف الجديد</label>
                </div>
                {phone_num.length!=10&&phone_num!=''||!validPhoneNumber(phone_num)?<label className="invalid-input-mes">تاكد من صحة رقم الهاتف</label>:<></>}
                <div className="center-div">
                <button type="submit" className="modal-submitbtn"
                onClick={handleSubmit}>حفظ</button>
                </div>
            </form>
        </div>
        </div>
        </div>
    )
}
