import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {IconButton,Typography} from "@mui/material";
import { Link } from "react-router-dom";
export default function ApplicantTypeModal({setOpen}) {
    return(
    <div className="modal-bg center-div">
      <div className="modal-container" id="applicant-type-contener">
        <div className="modal-header">
          <IconButton onClick={()=>{setOpen(false)}} style={{padding:"5px", float:"right" , paddingBottom:"0px"}}><ArrowBackIcon className="modal-icon"/></IconButton>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} 
          className='modal-name'>لمن العملية؟</Typography>
        </div>
        <div className="modal-body">
        <div className="center-div">
           <button type="button" className="modal-btn"
           onClick={()=>{
            localStorage.setItem('is_user', 1);
           }}>لنفسي</button>
           </div>
           <div className="center-div">
            <button type="button" className="modal-btn"
            onClick={()=>{
              localStorage.setItem('is_user', 0);
             }}>لشخص آخر</button> 
            </div>
            <div className="center-div">
            <Link to="apply-to-surgery" className="form-submitbtn" style={{textAlign:"center",textDecoration:"none"}}>متابعة</Link>
            </div>
        </div>
        </div>
        </div>
    )
}