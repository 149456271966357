import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {IconButton,Typography} from "@mui/material";
import Request from "../components/Request";
import axios from 'axios';
import { useNavigate  } from 'react-router-dom';
import{setSugeryId} from '../validations.js'

export default function SentRequestModal({setOpen}) {
  const navigate = useNavigate();
  const[requests,setRequests]=React.useState([]);
  const[foundData,setFoundData]=React.useState(false)
  React.useEffect(() => {
    const fetchData=async()=>{
      const token=localStorage.getItem('token')
      try{
        const response=await axios.get("https://ahmadproject.serv00.net/api/applicant_user_side",{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        if(response.data.data!=null){
          setFoundData(true);
        setRequests(response.data.data);}
        console.log(response)
      }
      catch (error) {
        console.log(error);
        if (error.response && error.response.status >= 500 && error.response.status < 600) {
          window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
        } else {
          window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
        }
      }
    }
    
    fetchData();
  }, []);
 
  
  
  return (
    <div className="modal-bg center-div">
        <div className="modal-container" id="requests-modal">
            <div className="modal-header">
            <IconButton onClick={()=>{setOpen(false)}} style={{padding:"5px", float:"right" , paddingBottom:"0px"}}><ArrowBackIcon className="modal-icon"/></IconButton>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} 
            className='modal-name'>الطلبات المرسلة</Typography>
            </div>
            <div className="modal-body">
              <div id="requests-menu">
                {foundData?requests.map(request=>(
                  <button style={{border:"none",padding:'0', backgroundColor:'transparent', width:"100%", height:'100%',marginBottom:"5px"}}className="center-div"
                  onClick={(event)=>{
                    setSugeryId(request.id)
                    navigate('/show-applicant')
                  }}
                  key={request.id}>
                    <Request request={request}/>
                    </button>
                )):<h3 className='center-div' style={{color:"rgb(92, 92, 92)"}}>لم تقم بالتقدم على اي عملية</h3>}
              </div>
            </div>
        </div>
    </div>
  )
}