import axios from "axios"
import React from "react";
import { isArabicText,validPhoneNumber,validTelephone } from "../validations";
import { useNavigate  } from 'react-router-dom';

export default function SurgeryApplicant(){
  const is_users=localStorage.getItem('is_user');
  const[fname,setFname]=React.useState('');
  const [mname,setMname]=React.useState('');
  const [lname,setLname]=React.useState('');
  const[phone_num,setPhoneNum]=React.useState('');
  const [whatsapp_num,setWhatsNum]=React.useState('');
  const[telephone,setTelephone]=React.useState('');
  const[gender,setGender]=React.useState('');
  const[birth_date,setBirth]=React.useState('');
  const[region_id,setRegionId]=React.useState(1);
  const[city_id,setCityId]=React.useState(1);
  const[applied_for_medical_dept_id,setDeptId]=React.useState(1);
  const[applied_for_surgery_id,setSurgeryId]=React.useState(1);
  const[lockPInfo,setLockPInfo]=React.useState(false);
  const[patient_note,setPatientNote]=React.useState('');
  const[medical_complaint,setMedicalComplaint]=React.useState('');
  const [cities,setCities]=React.useState([]);
  const [regions,setRegions]=React.useState([]);
  const[medical_depts,setMedicalDepts]=React.useState([]);
  const[surgeries,setSurgeries]=React.useState([]);
  const[can_visit_damascus,setCanvisitDamas]=React.useState(null);
  const token=localStorage.getItem('token');
  const navigate = useNavigate();
  React.useEffect(() => {
    // console.log(is_user)
      const fetchData = async () => {
        try {
          const Response = await axios.get("https://ahmadproject.serv00.net/api/data_regions_surgeries",{
              headers:{
                  'Authorization': `Bearer ${token}`
              }
            });
          setRegions(Response.data.data.regions);
          setCities(Response.data.data.cities);
          setMedicalDepts(Response.data.data.medicalDepts);
          setSurgeries(Response.data.data.surgeries);
        } catch (error) {
          if (error.response && error.response.status >= 500 && error.response.status < 600) {
            window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
          } else {
            window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
          }
        }
        if(is_users==1){
        try {
          const Response = await axios.get("https://ahmadproject.serv00.net/api/personal_info/736", {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          setFname(Response.data.data.fname);
          setMname(Response.data.data.mname);
          setLname(Response.data.data.lname);
          setPhoneNum(Response.data.data.phone_num);
          setTelephone(Response.data.data.telephone);
          setWhatsNum(Response.data.data.whatsapp_num);
          setGender(Response.data.data.gender);
          setBirth(Response.data.data.birth_date);
          setRegionId(Response.data.data.region_id);
          setCityId(Response.data.data.city_id);
          setRegions({id:Response.data.data.region_id,name:Response.data.data.region_name})
          setCities({id:Response.data.data.city_id,name:Response.data.data.city_name})
          setLockPInfo(true);
        } catch (error) {
          // console.log(error);
          if (error.response && error.response.status === 404) {
            
          } else if (error.response && error.response.status >= 500 && error.response.status < 600) {
            window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
          } else {
            window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
          }
        }
      }
      };
    
      fetchData();
    }, []);

    const sendData=()=>{
      let data;
      if(!lockPInfo){
        data={
          is_users,
          fname,
          mname,
          lname,
          phone_num,
          whatsapp_num,
          telephone,
          gender,
          region_id,
          applied_for_medical_dept_id,
          applied_for_surgery_id,
          patient_note,
          medical_complaint,
          birth_date,
          can_visit_damascus
        }
      }
      else{
        data={
          applied_for_medical_dept_id,
          applied_for_surgery_id,
          patient_note,
          medical_complaint,
          is_users
        }
      }
      console.log(data)
      axios.post("https://ahmadproject.serv00.net/api/applicant_user_side",data,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response=>{
        window.alert("تم الارسال")
        navigate("/");
     })
     .catch(error=>{
         if (error.response && error.response.status >= 500 && error.response.status < 600) {
          console.log(error)
          // console.log(data)
          // Display an alert for server error
          for(let i=0;i<1;i++)
          window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
        } else {
          // Display an alert with the error message from the server
          for(let i=0;i<1;i++)
          window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
        }
     })

    }
    const handleSubmit=(e)=>{
      e.preventDefault();
      if(isArabicText(fname)&&isArabicText(mname)&&isArabicText(lname)&&validPhoneNumber(phone_num)&&validPhoneNumber(whatsapp_num)&&validTelephone(telephone)){
        if(fname!=''&&mname!=''&&lname!=''&&phone_num!=''&telephone!=''&&medical_complaint!=''&&gender!=''&&birth_date!=''&&whatsapp_num!='')
          sendData();}
      else window.alert('يجب ملئ جميع الحقول بشكل صحيح')
    };
    
    
  

return <>
      <div className="page-background center-div" id="applicant-background">
          <div className="form-contener" id="applicant-contener">
              <form>
              <div className='center-div page-title'>
                  <h1 style={{margin:"10px 0"}}>التقدم الى عملية</h1>
              </div>
              <div className="center-div form-body">
              <input type="text" placeholder={fname?fname:"الاسم الاول"} className="input-box" required={true} disabled={lockPInfo}
              style={!isArabicText(fname)?{borderColor:"red"}:{borderColor:"lightgray"}}
              onChange={(e)=>{
                  setFname(e.target.value);
              }}/>
              </div>
              {/* error message */}
              {!isArabicText(fname)?<label className="invalid-input-mes">الرجاء ادخال الاسم بالعربية فقط</label>:<></>}

              <div className="center-div form-body">
              <input type="text" placeholder={mname?mname:"اسم الاب"} className="input-box" required={true} disabled={lockPInfo}
              style={!isArabicText(mname)?{borderColor:"red"}:{borderColor:"lightgray"}}
              onChange={(e)=>{
                  setMname(e.target.value);
              }}/>
              </div>
              {/* error message */}
              {!isArabicText(mname)?<label className="invalid-input-mes">الرجاء ادخال اسم الاب بالعربية فقط</label>:<></>}

              <div className="center-div form-body">
              <input type="text" placeholder={lname?lname:"اللقب / الكنية"} className="input-box" required={true} disabled={lockPInfo}
              style={!isArabicText(lname)?{borderColor:"red"}:{borderColor:"lightgray"}}
              onChange={(e)=>{
                  setLname(e.target.value);
              }}/>
              </div>
              {/* error message */}
              {!isArabicText(lname)?<label className="invalid-input-mes">الرجاء ادخال الكنية / الللقب بالعربية فقط</label>:<></>}

              <div className="input-label">
              <label htmlFor="input" >المحافظة</label>
              </div>
              <div className="center-div form-body">
              <select name="cities" id="cities" className="input-box" onChange={(e)=>{
                  setCityId(e.target.value)
              }}>
              {lockPInfo?
              <option key={cities.id} value={cities.id}>{cities.name}</option>
              :cities.map(city => (
              <option key={city.id} value={city.id}>{city.name}</option>
              ))}
              </select>

              </div>
              <div className="input-label">
              <label htmlFor="input" >المدينة</label>
              </div>
              <div className="center-div form-body">
              <select name="regions" id="regions" className="input-box"
              onChange={(e)=>{
                  setRegionId(e.target.value)
              }}>

                {lockPInfo?
                <option key={regions.id} value={regions.id}>{regions.name}</option>
                :regions.filter(region => region.city_id == city_id)
                .map(region => (
              <option key={region.id} value={region.id}>{region.name}</option>
              ))}
              </select>
              </div>
              {city_id==1?<></>:
              <>
              <div className="input-label">
              <label htmlFor="input" >هل يمكنك زيارة دمشق</label>
              </div>
              <div className="center-div">
                  <div className="radio-input">
                  <label className="radio-label">نعم</label>
                  <input type="radio" value="true" name="gender" disabled={lockPInfo}
                  onChange={(e)=>{
                      setCanvisitDamas(true)
                  }}
                  checked={can_visit_damascus === true}/>
                  </div>
                  <div className="radio-input">
                  <label className="radio-label">لا</label>
                  <input type="radio" value="false" name="gender" disabled={lockPInfo}
                  onChange={(e)=>{
                      setCanvisitDamas(false)
                  }}
                  checked={can_visit_damascus === false}/>
                  </div>
              </div>
              </>}
              <div className="input-label">
              <label htmlFor="input" >تاريخ الميلاد</label>
              </div>
              <div className="center-div ">
              <input type="date" className="input-box" style={{fontSize:"large", color:"darkslategrey", textAlign:"right"}} disabled={lockPInfo}
              onChange={(e)=>{
                  setBirth(e.target.value);
              }}
              value={birth_date?birth_date:""}/>
              </div>

              <div className="center-div form-body">
              <input type="tel" placeholder={phone_num?phone_num:"رقم الهاتف"} className="input-box" required disabled={lockPInfo}
              pattern = "^09\d{8}$"
              style={!validPhoneNumber(phone_num)?{borderColor:"red"}:{borderColor:"lightgray"}}
              onChange={(e)=>{
                  setPhoneNum(e.target.value);
              }}
              />
              </div>
              {/* error message */}
              {!validPhoneNumber(phone_num)?<label className="invalid-input-mes">الرجاء ادخال رقم الهاتف بشكل صحيح</label>:<></>}

              <div className="center-div form-body">
              <input type="tel" placeholder={whatsapp_num?whatsapp_num:"رقم الواتساب"} className="input-box"  disabled={lockPInfo}
              pattern = "^09\d{8}$"
              style={!validPhoneNumber(whatsapp_num)?{borderColor:"red"}:{borderColor:"lightgray"}}
              onChange={(e)=>{
                  setWhatsNum(e.target.value);
              }}
              />
              </div>
              {/* error message */}
              {!validPhoneNumber(whatsapp_num)?<label className="invalid-input-mes">الرجاء ادخال رقم الهاتف بشكل صحيح</label>:<></>}


              {/* update to show error */}
              <div className="center-div form-body">
                  <input type="tel"placeholder={telephone?telephone:"رقم الهاتف الأرضي"} className="input-box" disabled={lockPInfo}
                      onChange={(e)=>{
                          setTelephone(e.target.value);
                      }}
                  />
              </div>
              {/* error message */}
              {!validTelephone(telephone)?<label className="invalid-input-mes">الرجاء ادخال رقم الارضي  بشكل صحيح مع رمز المحافظة</label>:<></>}
              
              <div className="input-label">
              <label htmlFor="input" >الجنس</label>
              </div>
              <div className="center-div">
                  <div className="radio-input">
                  <label className="radio-label">انثى</label>
                  <input type="radio" value="female" name="gender" disabled={lockPInfo}
                  onChange={(e)=>{
                      setGender("أنثى")
                  }}
                  checked={gender === "أنثى"}/>
                  </div>
                  <div className="radio-input">
                  <label className="radio-label">ذكر</label>
                  <input type="radio" value="male" name="gender" disabled={lockPInfo}
                  onChange={(e)=>{
                      setGender("ذكر")
                  }}
                  checked={gender === "ذكر"}/>
                  </div>
              </div>
              <div className="input-label">
              <label htmlFor="input" >الاختصاص</label>
              </div>
              <div className="center-div form-body">
              <select name="medicalDept" id="medicalDept" className="input-box"
              onChange={(e)=>{
                  setDeptId(e.target.value);
              }}>
              {medical_depts.map(dept => (
              <option key={dept.id} value={dept.id}>{dept.name}</option>
              ))}
              </select>
              </div>
              <div className="input-label">
              <label htmlFor="input" >العملية الجراحية</label>
              </div>
              <div className="center-div form-body">
              <select name="surgery" id="surgery" className="input-box"
              onChange={(e)=>{
                  setSurgeryId(e.target.value)
              }}>
              {surgeries.filter(surgery => surgery.medical_dept_id == applied_for_medical_dept_id)
                .map(surgery => (
              <option key={surgery.id} value={surgery.id}>{surgery.name}</option>
              ))}  
              </select>
              </div>
              <div className="input-label">
              <label htmlFor="input" >الشكاية الطبية</label>
              </div>
              <div className="center-div form-body">
              <textarea className="input-box" placeholder="مما تعاني؟ / ما هي شكايتك؟" maxLength='700'
              onChange={(e)=>{
                setMedicalComplaint(e.target.value);
              }}/>
              </div>
              <div className="input-label">
              <label htmlFor="input" >ملاحظات</label>
              </div>
              <div className="center-div form-body">
              <textarea className="input-box" placeholder="في حال كان لديك اي ملاحظات يمكنك اضافتها هنا" maxLength='700'
              onChange={(e)=>{
                setPatientNote(e.target.value);
              }}/>
              </div>
              <button type="submit" onClick={(e)=>{
                handleSubmit(e)
              }} className="form-submitbtn">ارسال</button>
          </form>
      </div>
  </div>
  </>
}