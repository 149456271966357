import React from "react";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {IconButton,Typography} from "@mui/material";
import { getAppoitmentId } from "../validations";
export default function FullAppointment({setOpen}) {
    const[surgeryDate,setSurgeryDate]=React.useState("")
    const[doctorName,setDoctorName]=React.useState("")
    const[hospitalName,setHospitalName]=React.useState("")
    const[regionName,setRegionName]=React.useState("")
    const[hospitalAdress,setHospitalAdress]=React.useState("")
    let token=localStorage.getItem('token');
    const id=getAppoitmentId()

  React.useEffect(() => {
    const fetchData=async()=>{

      try{
        const response=await axios.get(`https://ahmadproject.serv00.net/api/user/showForUserSurgeriesSchedule/${id}`,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        setDoctorName(response.data.data.surgery_doctor_full_name);
        setSurgeryDate(response.data.data.surgery_date);
        setHospitalName(response.data.data.surgery_hospital_name);
        setRegionName(response.data.data.surgery_hospital_region_name);
        setHospitalAdress(response.data.data.surgery_hospital_street_address)
        console.log(response)
      }
      catch (error) {
        if (error.response && error.response.status >= 500 && error.response.status < 600) {
          window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
        } else {
          window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
        }
      }
    }
    
    fetchData();
  }, []);
    return(
      <div className="modal-bg center-div">
      <div className="modal-container" id="requests-modal">
          <div className="modal-header">
          <IconButton onClick={()=>{setOpen(false)}} style={{padding:"5px", float:"right" , paddingBottom:"0px"}}><ArrowBackIcon className="modal-icon"/></IconButton>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} 
          className='modal-name'></Typography>
          </div>
        <div className="modal-body">
            <form>
              <div id="profile-form" className="center-div">
              <h5 className="form-label">اسم الطبيب</h5>
              <input type="text" placeholder={doctorName} className="form-input"disabled={true}
              />
              <div id="profile-form" className="center-div ">
              <h5 className="form-label">اسم المشفى</h5>
              <input type="text" value={hospitalName} className="form-input fullapp" disabled={true}
              />
              
              <div id="profile-form" style={{alignSelf:"end"}}>
              <h5 className="form-label">موعد العملية</h5>
              </div>
              <div className="center-div ">
              <input type="date time" className="input-box fullapp" style={{fontSize:"large", color:"darkslategrey", textAlign:"right"}} disabled={true}
              value={surgeryDate}/>
              </div>
              <div id="profile-form" className="center-div">
              <h5 className="form-label">اسم المنطقة</h5>
              <input type="text" placeholder={regionName} className="form-input fullapp"disabled={true}/>
              </div>

              <div id="profile-form" className="center-div">
              <h5 className="form-label">العنوان</h5>
              <input type="text" placeholder={hospitalAdress} className="form-input fullapp"disabled={true}/>
              </div>

              </div>
              </div>
            </form>
        </div>
        </div>
        </div>
    )
}