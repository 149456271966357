import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import  Drawer  from './Drawer';

export default function ButtonAppBar({setOpenSettings,setOpenProfile,setOpenRequests}) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundImage: "linear-gradient(#4e8784,#689694)"}} id="appBar-box">
        {/* change color to transperant */}
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            
          >
           <Drawer setOpenSettings={setOpenSettings} setOpenProfile={setOpenProfile} setOpenRequests={setOpenRequests}/> 
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} 
          className='center-div' id='page-name'>الصفحة الرئيسية</Typography>
            
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            style={{marginRight:"0"}}
          >
            <NotificationsNoneOutlinedIcon className='AppBar-icon'/>
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}


//// if not loged in 
///<Button color="inherit">Login</Button>