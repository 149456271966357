import React from "react";
import AppBar from ".././components/AppBar";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import SettingsModal from "../modals/SettingsModal";
import ChangePassModal from "../modals/ChangePassModal";
import "../App.css"
import ChangeNumberModal from "../modals/ChangeNumberModal";
import ProfileModal from "../modals/ProfileModal"
import SentRequestsModal from "../modals/SentRequestsModal"
import ApplicantTypeModal from "../modals/ApplicantTypeModal"
import AppointmentsModal from "../modals/AppointmentsModal";
import FullAppointment from "../modals/FullAppointmentModal";


export default function Home(){
const[openSettings,setOpenSettings]=React.useState(false);
const [openPassChange,setOpenPassChange]=React.useState(false);
  const [openNumChange,setOpenNumChange]=React.useState(false);
  const[openProfile,setOpenProfile]=React.useState(false);
  const [openRequests,setOpenRequests]=React.useState(false);
  const [openApplicantType,setOpenApplicantType]=React.useState(false);
  const [openAppointments,setOpenAppointments]=React.useState(false)
  const [openFullApp,setOpenFullApp]=React.useState(false)
    return<>
    <div id="home-body">
    
        <AppBar setOpenSettings={setOpenSettings} setOpenProfile={setOpenProfile} setOpenRequests={setOpenRequests}/>
        <div className="center-div" id="home-image">
        <img src="../../assests/surgery.jpg" id='homebg'/>
        </div>
        <div className="center-div">
            <div className="homebtn-box">
                <ListItemButton style={{display:"block",margin:"0",padding:"0",textAlign:"right"}}
                onClick={()=>{
                    setOpenAppointments(true)
                }}>
                    <ListItemIcon><EventNoteIcon className="homebtn-icons"/></ListItemIcon>
                    <h2 className="homebtn-title">مواعيدي</h2>
                    <span className="homebtn-disc">اضغط لرؤية جميع المواعيد الخاصة بك</span>
                </ListItemButton>
            </div>

            <div className="homebtn-box">
                <ListItemButton style={{display:"block",margin:"0",padding:"0",textAlign:"right"}}
                onClick={()=>{
                    setOpenApplicantType(true);
                }}
                >
                    <ListItemIcon><AirlineSeatFlatIcon className="homebtn-icons"/></ListItemIcon>
                    <h2 className="homebtn-title">التقدم إلى عملية</h2>
                    <span className="homebtn-disc">اضغط هنا للتقدم لتعبئة الاستمارة</span>
                </ListItemButton>
            </div>
        </div>
    </div>
    {openSettings ? <SettingsModal setOpen={setOpenSettings} setOpenPassChange={setOpenPassChange} setOpenNumChange={setOpenNumChange} /> : <></>}
    {openPassChange?<ChangePassModal setOpen={setOpenPassChange}/>:<></>}
    {openNumChange?<ChangeNumberModal setOpen={setOpenNumChange}/>:<></>}
    {openProfile?<ProfileModal setOpen={setOpenProfile}/>:<></>}
    {openRequests?<SentRequestsModal setOpen={setOpenRequests}/>:<></>}
    {openApplicantType?<ApplicantTypeModal setOpen={setOpenApplicantType}/>:<></>}
    {openAppointments?<AppointmentsModal setOpen={setOpenAppointments} openFullApp={setOpenFullApp}/>:<></>}
    {openFullApp?<FullAppointment setOpen={setOpenFullApp}/>:<></>}
   </>
}