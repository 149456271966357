import React from "react";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {IconButton,Typography} from "@mui/material";
export default function ProfileModal({setOpen}) {
  const [fname, setFName] = React.useState('');
  const [mname, setMName] = React.useState('');
  const [lname,setLName]=React.useState('');
  const [phone_num,setPhoneNum]=React.useState('');
  const[whatsapp_num,setWhatsNum]=React.useState('');
  const[telephone,setTelephone]=React.useState('');
  const[birth_date,setBirthDate]=React.useState('');
  const[is_users,setUser]=React.useState(0);
  const [cities,setCities]=React.useState([]);
  const [regions,setRegions]=React.useState([]);
  const[region_id,setRegionId]=React.useState(1);
  const[city_id,setCityId]=React.useState(1);
  const[can_update_info,setCanUpdate]=React.useState(true);
  const[can_delete_info,setCanDelete]=React.useState(true);
  const[allCities,setAllCities]=React.useState([])
  const[allRegions,setAllRegions]=React.useState([])
  const[gender,setGender]=React.useState()
    let token=localStorage.getItem('token');



  React.useEffect(() => {
    const fetchData=async()=>{

      try{
        const response=await axios.get("https://ahmadproject.serv00.net/api/personal_info/736",{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        console.log(response);
        setFName(response.data.data.fname);
        setMName(response.data.data.mname);
        setLName(response.data.data.lname);
        setPhoneNum(response.data.data.phone_num);
        setWhatsNum(response.data.data.whatsapp_num);
        setTelephone(response.data.data.telephone);
        setBirthDate(response.data.data.birth_date);
        setRegionId(response.data.data.region_id);
        setCityId(response.data.data.city_id);
        setRegions({id:response.data.data.region_id,name:response.data.data.region_name})
        setCities({id:response.data.data.city_id,name:response.data.data.city_name})
        setCanDelete(response.data.can_delete_info);
        setGender(response.data.data.gender)
        setCanUpdate(response.data.can_update_info);
        setUser(1)
        if(!response.data.can_update_info)
          window.alert('لا يمكن تعديل المعلومات في الوقت الحالي')
      }
      catch (error) {
        // console.log(error);
        if (error.response && error.response.status === 404) {
          window.alert("المستخدم الحالي ليس لديه معلومات شخصية")
        } else
        if (error.response && error.response.status >= 500 && error.response.status < 600) {
          window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
        } else {
          window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
        }
      }
      
      try {
        const Response = await axios.get("https://ahmadproject.serv00.net/api/data_regions_surgeries",{
            headers:{
                'Authorization': `Bearer ${token}`
            }
          });
        setAllRegions(Response.data.data.regions);
        setAllCities(Response.data.data.cities);
       
        
      } catch (error) {
        if (error.response && error.response.status >= 500 && error.response.status < 600) {
          window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
        } else {
          window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
        }
    }
    }
    
    fetchData();
  }, []);
  const sendData=()=>{
    
    const data={
      fname,
      mname,
      lname,
      phone_num,
      whatsapp_num,
      telephone,
      birth_date,
      is_users,
      city_id,
      region_id,
      gender
    };
    axios.post("https://ahmadproject.serv00.net/api/personal_info_user",data,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response=>{
          // console.log(response)
          window.alert("تم الحفظ")
          setOpen(false)
      })
      .catch(error => {
        
        if (error.response && error.response.status >= 500 && error.response.status < 600) {
          // Display an alert for server error
          window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
        } else {
          // Display an alert with the error message from the server
          window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
        }
        console.log(error)
      })
      }
  
  const deleteData=()=>{
    if(!can_delete_info){
      window.alert("لا يمكن حذف المعلومات الشخصية في الوقت الحالي الرجاء المحاولة لاحقاً")
      return
    }
    axios.delete("https://ahmadproject.serv00.net/api/personal_info/0",{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response=>{
          window.alert("تم الحذف")
          setOpen(false)
      })
      .catch(error => {
        
        if (error.response && error.response.status >= 500 && error.response.status < 600) {
          // Display an alert for server error
          window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
        } else {
          // Display an alert with the error message from the server
          window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
        }
        console.log(error)
      })
  }
  const updateData=()=>{
    if(!can_update_info){
      window.alert('لا يمكن تعديل المعلومات الشخصية في الوقت الحالي الرجاء المحاولة لاحقاً')
      return;
    }
    const data={
      fname,
      mname,
      lname,
      phone_num,
      whatsapp_num,
      telephone,
      birth_date,
      is_users,
      city_id,
      region_id,
      gender
    };
    axios.put("https://ahmadproject.serv00.net/api/personal_info/0",data,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response=>{
          window.alert("تم الحفظ")
          setOpen(false)
      })
      .catch(error => {
        
        if (error.response && error.response.status >= 500 && error.response.status < 600) {
          // Display an alert for server error
          window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
        } else {
          // Display an alert with the error message from the server
          window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
        }
        console.log(error)
      })
      
  }

  const HandleSubmit = (event) => {
    event.preventDefault();
    if(is_users==0)
    sendData();
  else
    updateData();
  }
  const HandleDelete=(event)=>{
    event.preventDefault();
    deleteData();
    // console.log(can_delete_info)
  }
    return(
    <div className="modal-bg center-div" id="applicant-background">
      <div className="modal-container">
        <div className="modal-header">
          <IconButton onClick={()=>{setOpen(false)}} style={{padding:"5px", float:"right" , paddingBottom:"0px"}}><ArrowBackIcon className="modal-icon"/></IconButton>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} 
          className='modal-name'>الحساب الشخصي</Typography>
        </div>
        <div className="modal-body">
            <form>
              <div id="profile-form" className="center-div">
              <h5 className="form-label">الاسم الاول</h5>
              <input type="text" placeholder={fname} className="form-input"
              onChange={(e)=>{setFName(e.target.value)
              }}/>
              <h5 className="form-label">اسم الاب</h5>
              <input type="text" placeholder={mname} className="form-input"
              onChange={(e)=>{setMName(e.target.value)
              }}/>
              <h5 className="form-label">اللقب / الكنية</h5>
              <input type="text" placeholder={lname} className="form-input"
              onChange={(e)=>{setLName(e.target.value)
              }}/>
              <h5 className="form-label">الجنس</h5>
              <div className="center-div">
                    <div className="radio-input">
                    <label className="radio-label">انثى</label>
                    <input type="radio" value="female" name="gender"
                    onChange={(e)=>{
                        setGender("أنثى")
                    }}
                    checked={gender === "أنثى"}/>
                    </div>
                    <div className="radio-input">
                    <label className="radio-label">ذكر</label>
                    <input type="radio" value="male" name="gender" 
                    onChange={(e)=>{
                        setGender("ذكر")
                    }}
                    checked={gender === "ذكر"}/>
                    </div>
                    </div>
              <h5 className="form-label">رقم الهاتف</h5>
              <input type="tele" placeholder={phone_num} className="form-input"
              onChange={(e)=>{setPhoneNum(e.target.value)
              }}/>
              <h5 className="form-label">رقم الواتساب</h5>
              <input type="tele" placeholder={whatsapp_num} className="form-input"
              onChange={(e)=>{setWhatsNum(e.target.value)
              }}/>
               <h5 className="form-label">رقم الارضي</h5>
              <input type="tele" placeholder={telephone} className="form-input"
              onChange={(e)=>{setTelephone(e.target.value)
              }}/>
               <h5 className="form-label">تاريخ الميلاد</h5>
              <input type="date" value={birth_date} className="form-input"
              onChange={(e)=>{setBirthDate(e.target.value)
              }}/>
              <h5 className="form-label">المحافظة</h5>
              <select name="cities" id="cities" className="form-input" onChange={(e)=>{
                    setCityId(e.target.value)
                }}
                value={city_id}>
                {can_update_info?
                allCities.map(city => (
                <option key={city.id} value={city.id}>{city.name}</option>
                )):<option key={cities.id} value={cities.id}>{cities.name}</option>
                }
              </select>
              <h5 className="form-label">المدينة</h5>
              <select name="regions" id="regions" className="form-input"
                onChange={(e)=>{
                    setRegionId(e.target.value)
                }}
                value={region_id}>
                  {can_update_info?
                  allRegions.filter(region => region.city_id == city_id)
                  .map(region => (
                <option key={region.id} value={region.id}
                >{region.name}</option>
                )):
                <option key={regions.id} value={regions.id}>{regions.name}</option>
                }
                </select>
              </div>
              <div className="center-div">
                {is_users==1&&can_update_info||is_users==0?<button type="submit" className="modal-submitbtn"
              onClick={HandleSubmit}>حفظ</button>:<></>}
              {is_users==1&&can_delete_info?
              <button type="delete" className="modal-submitbtn"
              onClick={HandleDelete}
              >حذف</button>:<></>}
              </div>
            </form>
        </div>
        </div>
        </div>
    )
}