import React from "react";
import axios from "axios";
import { useNavigate  } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {IconButton,Typography} from "@mui/material";
import {validEmail,validCode} from "../validations.js";

export default function CheckCodeModal({setOpen}) {
  const navigate = useNavigate();
    const [email, setEmail] = React.useState('');
  const [code, setCode] = React.useState('');
  const handleSubmit=(e)=>{
    e.preventDefault();
    if(validEmail(email)&&validCode(code))
    sendData();
  else window.alert("الرجاء التحقق من صحة البيانات")
}
const sendData = () => {
    const url="https://ahmadproject.serv00.net/api/check_code";
    const info = {
        email,
        code
      };
    axios.post(url,info)
    .then(response=>{
        localStorage.setItem('token', response.data.token);
      navigate('/');
    })
    .catch(error => {
        if(error.response&&error.response.status==401){
            window.alert('ان الرمز المدخل خاطئ او منتهي الصلاحية')
        }
        else if (error.response && error.response.status >= 500 && error.response.status < 600) {
          // Display an alert for server error
          window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
        } else {
          // Display an alert with the error message from the server
          window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
        }
      });
    }
    return(
    <div className="modal-bg center-div">
      <div className="modal-container">
        <div className="modal-header">
          <IconButton onClick={()=>{setOpen(false)}} style={{padding:"5px", float:"right" , paddingBottom:"0px"}}><ArrowBackIcon className="modal-icon"/></IconButton>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} 
          className='modal-name'>ادخل رمز التحقق</Typography>
        </div>
        <div className="modal-body">
            <form>
                <div className="input-group">
                <input required type="email" autoComplete="off" className="input"
                style={!validEmail(email)?{borderColor:"red"}:{borderColor:"gray"}}
                onChange={(e)=>{
                  setEmail(e.target.value);
                }}/>
                <label className="user-label">البريد الالكتروني</label>
                </div>
                <div className="input-group">
                {/* error message */}
                {!validEmail(email)?<label className="invalid-input-mes"> الرجاء ادخال البريد الالكتروني بطريقة صحيحة</label>:<></>}
                </div>

                <div className="input-group">
                <input required type="number" autoComplete="off" className="input"
                style={!validCode(code)?{borderColor:"red"}:{borderColor:"gray"}}
                onChange={(e)=>{
                  setCode(e.target.value);
                }}/>
                <label className="user-label">رمز التحقق</label>
                </div>
                <div className="input-group">
                {/* error message */}
                {!validCode(code)?<label className="invalid-input-mes">الرمز مكون من 4 ارقام</label>:<></>}
                </div>

                <div className="center-div">
                <button type="submit" className="modal-submitbtn"
                onClick={handleSubmit}>حفظ</button>
                </div>
            </form>
        </div>
        </div>
        </div>
    )
}
