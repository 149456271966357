import React from "react";
import axios from "axios";
import { Link,useNavigate } from 'react-router-dom';
import CheckCodeModal from "../modals/CheckCodeModal.jsx";
import{validEmail} from "../validations.js"
export default function SigninEmail(){
    const navigate = useNavigate();
    const [email,setEmail]=React.useState('');
    const [password,setPassword]=React.useState('');
    const [openCodeCheck,setOpenCodeCheck]=React.useState(false)
    const[logedin,setLogedin]=React.useState(false);
    const handelSubmit=(e)=>{
        e.preventDefault();
        if(email===''||password==='')
        return;
        if(validEmail(email)&&password.length>=8)
            sendData();
    }
    
    const sendData=()=>{
        const url='https://ahmadproject.serv00.net/api/user/login';
        const info={
            email,
            password
        }
        axios.post(url,info)
        .then(response=>{
           console.log(response)
           if(response.status===250)
                setOpenCodeCheck(true)
            if(response.status===200)
                {
                localStorage.setItem('token', response.data.token);
                navigate('/');
                }
            setLogedin(true)
         })
         .catch(error => {
            if(error.response&&error.response.status===401){
                window.alert('ان الحساب المدخل غير موجود الرجاء التحقق من صحة البيانات والمحاولة مرة اخرى')
            }
            else if (error.response && error.response.status >= 500 && error.response.status < 600) {
              // Display an alert for server error
              window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
            } else {
              // Display an alert with the error message from the server
              window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
            }
         })
    }
if(logedin)
return <Link to="/"/>
return(
    <>
    <div className="page-background center-div">
            <div className="form-contener">
                <form>
                <div className='center-div page-title'>
                    <h1 style={{margin:"10px 0"}}>تسجيل الدخول</h1>
                </div>
                <div className="center-div form-body">
                <input type="email" placeholder="البريد الالكتروني" className="input-box" required
                style={!validEmail(email)?{borderColor:"red"}:{borderColor:"lightgray"}}
                onChange={(e)=>{
                    setEmail(e.target.value);
                }}
                />
                </div>
                {/* error message */}
                {!validEmail(email)?<label className="invalid-input-mes">الرجاء ادخال البريد الالكتروني بطريقة</label>:<></>}
                <div className="center-div form-body">
                <input type="password" placeholder="كلمة السر" className="input-box" required={true} minLength={8}
                style={password.length<8&&password!==''?{borderColor:"red"}:{borderColor:"lightgray"}}
                onChange={(e)=>{
                    setPassword(e.target.value);
                }}/>
                </div>
                {/* error message */}
                {password.length<8&&password!==''?<label className="invalid-input-mes">يجب ان تكون كلمة السر ٨ محارف على الاقل</label>:<></>}

                <div className="form-body">
                    <button type="submit" onClick={handelSubmit} className="form-submitbtn">تسجيل دخول</button>
                </div>
                <div className="form-links">
                    <div style={{float:"right", marginRight:"15px"}}>
                <Link to="../Sign-in-using-phone-number" className="info-label links">تسجيل الدخول عبر رقم الهاتف </Link>
                </div>
                </div>
                <div className="form-links">
                <div style={{float:"left", marginLeft:"15px" ,textAlign:"left"}}>
                <label className="info-label info-text">ليس لديك حساب؟</label>
                <Link to="../Register" className="info-label links">أنشاء حساب</Link>
                </div>
                </div>
                </form>
            </div>
    </div>
    {openCodeCheck?<CheckCodeModal setOpen={setOpenCodeCheck}/>:<></>}
    </>
)
}