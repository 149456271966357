import  React,{ useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import  axios  from 'axios';
import { useNavigate } from 'react-router-dom';
import "../App.css"

export default function AnchorTemporaryDrawer({setOpenSettings,setOpenProfile,setOpenRequests}) {
  const navigate=useNavigate()
  useEffect(()=>{
    if(window.innerWidth>=768)
      toggleDrawer('left',true)
    else
      toggleDrawer('left',false)
  })
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  
  
 
  

  const list = (anchor) => (
    <>
    <Box
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List disablePadding >
      <div className='drawer-header'>
        <img src="../../assests/highlightedLogo.svg" className='logo'/>
      </div>
      <div style={{display:"flex",justifyContent:"center",alignItems:'center',marginBottom:"20px"}}><div id="line"/></div>
      
      <ListItem disablePadding className="list-item">
            <ListItemButton onClick={()=>{setOpenProfile(true)}}>
                <ListItemIcon><PersonOutlinedIcon className='drawer-icon'/></ListItemIcon>
                <ListItemText primary={"الملف الشخصي"} className='primary-text'/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding className="list-item">
            <ListItemButton onClick={()=>{setOpenRequests(true)}}>
                <ListItemIcon><InventoryOutlinedIcon className='drawer-icon'/></ListItemIcon>
                <ListItemText primary={"الطلبات المرسلة"} className='primary-text'/>
            </ListItemButton>
        </ListItem>
        
        
      </List>
      <Divider />
      <List>
        <ListItem disablePadding className="list-item">
            <ListItemButton onClick={()=>{if (window.confirm('هل انت متأكد من انك تريد تسجيل الخروج؟')) {
              //update to refresh the page and continue as a guest
                const url='https://ahmadproject.serv00.net/api/user/logout';
                //need update so the token can be stored in local storage
                const token =localStorage.getItem('token');
                // console.log(token)
                axios.post(url,null,{
                  headers: {
                    'Authorization': `Bearer ${token}`
                  }
                })
                .then(response=>{
                  localStorage.setItem('token',null)
                  navigate("/home")
              })
              .catch(error => {
                 if (error.response && error.response.status >= 500 && error.response.status < 600) {
                  // Display an alert for server error
                  window.alert('عطل في المخدم الرجاء المحاولة مرة اخرى');
                } else {
                  // Display an alert with the error message from the server
                  window.alert('عطل في الشبكة الرجاء المحاولة مرة اخرى');
                }
              })
              } else {
                console.log('الغاء');
              }}}>
                <ListItemIcon><LogoutOutlinedIcon className='drawer-icon'/></ListItemIcon>
                <ListItemText primary={"تسجيل الخروج"} className='primary-text'/>
            </ListItemButton>
        </ListItem>
      </List>
      <List id="drawer-footer" >
        <ListItem disablePadding className="list-item" id='settings'>
            <ListItemButton onClick={()=>{setOpenSettings(true)}}>
                <ListItemIcon><SettingsOutlinedIcon className='drawer-icon'/></ListItemIcon>
                <ListItemText primary={"الإعدادات"} className='primary-text'/>
            </ListItemButton>
        </ListItem>
      </List>
    </Box>
    </>
    // مواقع المشروع
  );

  return (
    <div>
      <React.Fragment>
        {window.innerWidth>=768?<></>:<MenuOutlinedIcon
       onClick={toggleDrawer("left",true)}
       className='AppBar-icon'
       /> }

       <Drawer
            anchor="left"
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            PaperProps={{
              sx: {
                backgroundColor: "#F0EBF4",
                // boxShadow:"5px 15px 20px grey",
                boxShadow:window.innerWidth>=768?"0":"5px 15px 20px grey"
              }
            }}
            variant={window.innerWidth>=768?"permanent":"temporary"}
          >
            {list("left")}
            
          </Drawer>  
     
      </React.Fragment>
    </div>
  );
}
